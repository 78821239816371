import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Indoor_Cameras/IN-8401_WQHD/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-8401 2k+ WQHD Lense Adjustment",
  "path": "/Indoor_Cameras/IN-8401_WQHD/Lense_Adjustment/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-8401 2k+ WQHD Indoor IP Camera Adjust the focus of your camera",
  "image": "./P_SearchThumb_IN-8401WQHD_Lense_Adjust.webp",
  "social": "/images/Search/P_SearchThumb_IN-8401WQHD_Lense_Adjust.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-8401WQHD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='IN-8401 2k+ WQHD Lense Adjustment' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-8401 2k+ WQHD is the first INSTAR camera with the new 1440p chipset.' image='/images/Search/P_SearchThumb_IN-8401WQHD_Lense_Adjust.webp' twitter='/images/Search/P_SearchThumb_IN-8401WQHD_Lense_Adjust.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Innenkameras/IN-8401_WQHD/Objektivjustage/' locationFR='/fr/Indoor_Cameras/IN-8401_WQHD/Lense_Adjustment/' crumbLabel="Lense" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "in-8401-2k-wqhd-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-8401-2k-wqhd-manual",
        "aria-label": "in 8401 2k wqhd manual permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-8401 2k+ WQHD Manual`}</h2>
    <h3 {...{
      "id": "adjust-the-focus-of-your-in-8401-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#adjust-the-focus-of-your-in-8401-camera",
        "aria-label": "adjust the focus of your in 8401 camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adjust the focus of your IN-8401 camera`}</h3>
    <p>{`The IN-8401 2k+ WQHD standard lense has a depth of field that is adjusted to a distance of approx. 5 - 8m. In case your area of interest is out of focus, you will have to adjust the lense accordingly.`}</p>
    <p>{`By turning the lense to the left or right, you can adjust the focus to the desired area. Connect the power adapter and the network cable of the camera. Open the web interface and use the supplied tool to rotate the lense until you get a clear, sharp image.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5c5569f06be65e62f102b45a7bfc9057/aa878/INSTAR_IN-8401-WQHD_Lense_Adjustment.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA3UlEQVQY021QQW6DQAzc/z8jai7kVOVc5RM9VCkVFVKAQ9KwpcsuHttbLaCIkMzNmhmPx8Z733WdqsYlhONT3MvMr7W2tSIycRLjkL+f9xn1vagulzIziQzthYGkVzV1XRdFwTxGaUr0++yYvTR5HmMEsDS7r+PH4a2tTmkETNM0ZVk65yYFgODDtTr1P5d0vsgcrooY3eu22m16ayfKrEql24hCCKnC1GUuqyw6uD///alEMm40+lAshDC3eNg7JNviYfe/VAA8YvX/kWIAwrhRazMRASCiZ+YZt+R/ORHTjl97e8gAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5c5569f06be65e62f102b45a7bfc9057/e4706/INSTAR_IN-8401-WQHD_Lense_Adjustment.avif 230w", "/en/static/5c5569f06be65e62f102b45a7bfc9057/d1af7/INSTAR_IN-8401-WQHD_Lense_Adjustment.avif 460w", "/en/static/5c5569f06be65e62f102b45a7bfc9057/7f308/INSTAR_IN-8401-WQHD_Lense_Adjustment.avif 920w", "/en/static/5c5569f06be65e62f102b45a7bfc9057/e1c99/INSTAR_IN-8401-WQHD_Lense_Adjustment.avif 1380w", "/en/static/5c5569f06be65e62f102b45a7bfc9057/758e9/INSTAR_IN-8401-WQHD_Lense_Adjustment.avif 1385w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5c5569f06be65e62f102b45a7bfc9057/a0b58/INSTAR_IN-8401-WQHD_Lense_Adjustment.webp 230w", "/en/static/5c5569f06be65e62f102b45a7bfc9057/bc10c/INSTAR_IN-8401-WQHD_Lense_Adjustment.webp 460w", "/en/static/5c5569f06be65e62f102b45a7bfc9057/966d8/INSTAR_IN-8401-WQHD_Lense_Adjustment.webp 920w", "/en/static/5c5569f06be65e62f102b45a7bfc9057/445df/INSTAR_IN-8401-WQHD_Lense_Adjustment.webp 1380w", "/en/static/5c5569f06be65e62f102b45a7bfc9057/47c81/INSTAR_IN-8401-WQHD_Lense_Adjustment.webp 1385w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5c5569f06be65e62f102b45a7bfc9057/81c8e/INSTAR_IN-8401-WQHD_Lense_Adjustment.png 230w", "/en/static/5c5569f06be65e62f102b45a7bfc9057/08a84/INSTAR_IN-8401-WQHD_Lense_Adjustment.png 460w", "/en/static/5c5569f06be65e62f102b45a7bfc9057/c0255/INSTAR_IN-8401-WQHD_Lense_Adjustment.png 920w", "/en/static/5c5569f06be65e62f102b45a7bfc9057/b1001/INSTAR_IN-8401-WQHD_Lense_Adjustment.png 1380w", "/en/static/5c5569f06be65e62f102b45a7bfc9057/aa878/INSTAR_IN-8401-WQHD_Lense_Adjustment.png 1385w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5c5569f06be65e62f102b45a7bfc9057/c0255/INSTAR_IN-8401-WQHD_Lense_Adjustment.png",
              "alt": "IN-8401 WQHD Objektivjustage",
              "title": "IN-8401 WQHD Objektivjustage",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      